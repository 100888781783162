import React, { useState } from "react";

import countries from "../../utils/countries.json"

const agents = [
  '1...50',
  '51...100',
  '101...200',
  '201...500',
  '501...1000',
  '1000+',
]

const RequestDemoForm = ({textColor}) => {

  const initialValue = {
    fname: "",
    lname: "",
    company: "",
    email: "",
    phone: "",
    country: "",
    agents: "",
  };

  const closePopup = () => {
    setSubmitted(false);
  };
  
  const [isSubmitted, setSubmitted] = useState(false);

  const [formState, setFormState] = useState(initialValue);

  const handleChange = (e) => {
    const curKey = e.target.getAttribute("name");
    const curValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    const val = (curKey === 'email' || curKey === 'company' ) ? curValue : `${curValue.slice(0, 1).toUpperCase()}${curValue.slice(1)}`

    setFormState({ ...formState, [curKey]: val});
  };

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "demo",
        fname: formState.fname,
        lname: formState.lname,
        company: formState.company,
        email: formState.email,
        phone: formState.phone,
        country: formState.country,
        agents: formState.agents,
      }),
    })
      .then(() => {
        setTimeout(() => {
          setSubmitted(true);
          setFormState(initialValue);
        }, 1000);
      })
      .catch((error) => alert(error));
  };
  
  return (
    <div className="relative">
      <form 
        className={`${isSubmitted ? `invisible` : ``} flex  flex-wrap -mx-2 text-right`}
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="demo"
        onSubmit={handleSubmit}
        >
        <div className="my-2 px-2 flex flex-col-reverse w-full sm:w-1/2 text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="text"
            name="fname"
            id="fname"
            value={formState.fname}
            placeholder="" />
          <label htmlFor="fname" className="block cursor-pointer text-white">
            First Name
          </label>
        </div>
        <div className="my-2 px-2 flex flex-col-reverse w-full sm:w-1/2 text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="text"
            name="lname"
            id="lname"
            value={formState.lname}
            placeholder="" />
          <label htmlFor="lname" className="block cursor-pointer text-white">
            Last Name
          </label>
      
        </div>
        <div className="my-2 px-2 flex flex-col-reverse w-full sm:w-1/2 text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="tel"
            name="phone"
            id="phone"
            value={formState.phone}
            placeholder="" />
          <label htmlFor="phone" className="block cursor-pointer text-white">
            Phone
          </label>
        </div>
        <div className="my-2 px-2 flex flex-col-reverse w-full sm:w-1/2 text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="email"
            name="email"
            id="email"
            value={formState.email}
            placeholder="" />
          <label htmlFor="email" className="block cursor-pointer text-white">
            Work Email
          </label>
        </div>
        <div className="my-2 px-2 flex flex-col-reverse w-full text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="text"
            name="company"
            id="company"
            value={formState.company}
            placeholder="" />
          <label htmlFor="company" className="block cursor-pointer text-white">
            Company Name
          </label>
        </div>
        <div className="my-2 px-2 flex flex-col-reverse w-full text-left">
          <select
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3.5 w-full focus:outline-none`}
            onChange={handleChange}
            name="country"
            id="country"
            value={formState.country}
            placeholder="">
              <option value={``}>select country</option>
             {
               countries.map((country, idx) => <option key={idx} value={country.name}>{country.name}</option>)
             }
          </select>
          <label htmlFor="country" className="block cursor-pointer text-white">
            Country
          </label>
        </div>
        <div className="my-2 px-2 flex flex-col-reverse w-full text-left">
          <select
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3.5 w-full focus:outline-none`}
            onChange={handleChange}
            name="agents"
            id="agents"
            value={formState.agents}
            placeholder="">
              <option value="">select</option>
              {
                agents.map((agent, idx) => <option key={idx} value={agent}>{agent}</option>)
              }
          </select>
          <label htmlFor="agents" className="block cursor-pointer text-white">
            Number of Agents
          </label>
        </div>
        <div className="mt-4 px-2 flex">
          <button 
            className="btn btn-white" 
            type="submit"
            title="Submit Request a Demo Form"
          >
            Request a Demo
          </button>
        </div>
      </form>
        {isSubmitted && (
          <div className={`h-64 text-left flex justify-center items-start flex-col absolute inset-0`}>
            <p className="font-light text-xl text-white">Your request has been sent.</p>
            <p className="text-grey-400 mb-6 text-white">We will reach you soon.</p>
            <p>
              <button type="button" className="text-white font-bold underline"
                onClick={closePopup}
              >Back to form</button>
            </p>
          </div>
        )}
    </div>
  )
}

export default RequestDemoForm;