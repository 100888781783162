import React from "react";
import { renderSmRTF } from "../rich-text";
import RequestDemoForm from "../content/demo-form";

const HeroRequestDemo = ({ data }) => {

  return (
    <header>
      <div className="xl:container px-4 xl:px-0">
        <div
          className={`flex-wrap flex items-end justify-center lg:justify-between  -mx-4 space-y-6 md:space-y-0`}
        >
          <div className={`lg:w-6/12 xl:w-6/12 w-full px-4 pt-28 lg:pt-10`}>
            <div className="max-w-lg xl:max-w-xl mx-auto">{renderSmRTF(data.text)}</div>
          </div>
          <div className={`
            max-w-xl
            lg:max-w-full
            lg:w-6/12
            w-full px-4`}>
            <div className="bg-accent lg:rounded-bl-40 rounded-20 lg:rounded-tr-none lg:rounded-tl-none lg:rounded-br-40 lg:rounded-br-none p-8 xl:px-16 py-6 lg:pb-14 pt-10 lg:pt-40">
              <p className="text-white font-semibold text-xl lg:text-2xl mb-6">Schedule Your Demo</p>
              <RequestDemoForm />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeroRequestDemo;
