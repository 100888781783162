import React from "react"
import { graphql } from "gatsby"
import { MarketingLayout } from "../components/common";
import SEO from "../components/Seo"

import HeroRequestDemo from "../components/hero/request-demo";
import Section from "../components/content/section";
import SectionTitle from "../components/content/section-title";
import SectionWithCards from "../components/content/section-w-cards";
import SectionLogos from "../components/carousel/main";
import SectionMedia from "../components/content/section-media";
import SectionContact from "../components/content/section-contact";
import SectionHighlightsV2 from "../components/content/section-highlights-v2";
// import SubscribeSection from "../components/subscribe/email";
import SectionMultiRef from "../components/section-multi-ref";
import SectionWithItems from "../components/content/section-w-items";
import BlockquoteSection from "../components/content/section-blockquote";
import SectionSplashImage from "../components/content/section-splash-image";
import SectionWithCollapsible from "../components/content/section-w-collapsible";
import SectionLayeredImages from "../components/content/section-layered-images";
import SectionWithModules from "../components/content/section-w-modules";

const MarketingPageTemplate = ({ data }) => {
  const page = data.page.edges[0].node
  const pageBody = page.pageBody

  return (
    <MarketingLayout>
      <SEO title={page.title} description={page.description} canonical />
      <div className="page-content">
        {pageBody.map((item) => {
          switch (item.__typename) {
            case "ContentfulMarketingHero":
              return <HeroRequestDemo data={item} />
            case "ContentfulSectionRegular":
              return <Section data={item} />
            case "ContentfulSectionTitle":
              return <SectionTitle data={item} />
            case "ContentfulSectionCards":
              return <SectionWithCards data={item} />
            case "ContentfulSectionLogos":
              return <SectionLogos data={item} />
            case "ContentfulSectionMedia":
              return <SectionMedia data={item} />
            case "ContentfulSectionHighlight":
              return <SectionHighlightsV2 data={item} />
            // case "ContentfulSectionSubscribe":
            //   return <SubscribeSection data={item} />
            case "ContentfulSectionMultiRef":
              return <SectionMultiRef data={item} />
            case "ContentfulSectionColumnCards":
              return <SectionWithItems data={item} />
            case "ContentfulSectionBlockquote":
              return <BlockquoteSection data={item} />
            case "ContentfulSectionGetInTouch":
              return <SectionContact data={item} />
            case "ContentfulSectionSplashImageCustom":
              return <SectionSplashImage data={item} />
            case "ContentfulSectionCollapsibleCustom":
              return <SectionWithCollapsible data={item} />
            case "ContentfulSectionLayeredImages":
              return <SectionLayeredImages data={item} />
            case "ContentfulSectionModules":
              return <SectionWithModules data={item} />
            default:
              return ''
          }
        })}
        {/* <div /> */}
      </div>
    </MarketingLayout>
  )
}

export default MarketingPageTemplate

export const pageQuery = graphql`
  query MarketingPageBySlug($slug: String!) {

    page: allContentfulMarketingPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          description
          title
          slug
          pageBody {
            ... on ContentfulMarketingHero {
              id
              name
              __typename
              text {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    title
                    file {
                      url
                      fileName
                      details {
                        image {
                          height
                          width
                        }
                      }
                    }
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              backgroundColor
            }
            ... on ContentfulSectionCards {
              id
              name
              cardCentred
              cardType
              __typename
              text {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    title
                    file {
                      url
                      fileName
                      details {
                        image {
                          height
                          width
                        }
                      }
                    }
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              backgroundColor
             
              cards {
                heading
                subHeading
                icon {
                  file {
                    url
                  }
                  title
                }
                link
              }
            }
            ... on ContentfulSectionLogos {
              id
              name
              sectionType
              __typename
              titleText {
                raw
              }
              images {
                file {
                  url
                }
                title
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            ... on ContentfulSectionMultiRef {
              id
              name
              __typename
              blocks {
                backgroundColor
                image {
                  gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                  title
                }
                icon {
                  file {
                    url
                  }
                  title
                }
                items {
                  heading
                  subHeading
                }
                __typename
                text {
                  raw
                  # references {
                  #   ... on ContentfulAsset {
                  #     contentful_id
                  #     __typename
                  #     title
                  #     fluid(maxWidth: 1200) {
                  #       ...GatsbyContentfulFluid
                  #     }
                  #     file {
                  #       url
                  #       fileName
                  #     }
                  #   }
                  # }
                }
              }
            }
          }
        }
      }
    }
  }
`
